import * as React from "react";
const LogoWhite = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1170 655"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <g transform="matrix(1.94717,0,0,1.94717,-373.322,-1811.27)">
      <g transform="matrix(0.926884,0,0,0.926884,28.384,185.391)">
        <path
          d="M176.226,803.569L521.959,803.569L521.959,1139.3L444.326,1130.24L444.326,867.816L176.226,867.816L176.226,803.569Z"
          style={{
            fill: "white",
            fillRule: "nonzero",
          }}
        />
      </g>
      <g transform="matrix(0.926884,0,0,0.926884,413.014,239.564)">
        <path
          d="M255.6,1098.19L334.571,1107.4L334.571,1036.02L255.6,1036.02L255.6,1098.19Z"
          style={{
            fill: "white",
            fillRule: "nonzero",
          }}
        />
      </g>
      <g transform="matrix(1.22301,0,0,1.22301,-223.298,189.699)">
        <path
          d="M660.91,687.309L660.91,605.481L830.654,605.481L830.654,687.894L773.847,758.563L773.847,803.289L713.997,803.289L713.997,758.563L770.218,687.309L770.466,654.172L721.098,654.172L721.098,687.309L660.91,687.309Z"
          style={{
            fill: "white",
          }}
        />
      </g>
    </g>
  </svg>
);
export default LogoWhite;
