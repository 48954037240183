import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls, Stars } from "@react-three/drei";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { motion as motion3d } from "framer-motion-3d";
import { useState, useEffect, useRef } from "react";

const GroundPlane = () => {
  return (
    <mesh receiveShadow rotation={[4.8, 0, 0]} position={[0, -5, 0]}>
      <planeBufferGeometry attach="geometry" args={[100, 100]} />
      <meshStandardMaterial attach="material" color="white" />
    </mesh>
  );
};
const Roof = () => {
  return (
    <mesh receiveShadow rotation={[-5.1, 0, 0]} position={[0, 30, 0]}>
      <planeBufferGeometry attach="geometry" args={[100, 120]} />
      <meshStandardMaterial attach="material" color="white" />
    </mesh>
  );
};
const BackDrop = () => {
  return (
    <mesh receiveShadow position={[0, -1, -7]}>
      <planeBufferGeometry attach="geometry" args={[100, 100]} />
      <meshStandardMaterial attach="material" color="white" />
    </mesh>
  );
};
const Front = () => {
  return (
    <mesh receiveShadow position={[0, -1, 30]}>
      <planeBufferGeometry attach="geometry" args={[100, 100]} />
      <meshStandardMaterial attach="material" color="white" />
    </mesh>
  );
};
const Left = () => {
  return (
    <mesh receiveShadow rotation={[0, -4.8, 0]} position={[-20, -2, 0]}>
      <planeBufferGeometry attach="geometry" args={[100, 100]} />
      <meshStandardMaterial attach="material" color="white" />
    </mesh>
  );
};
const Right = () => {
  return (
    <mesh receiveShadow rotation={[0, 4.8, 0]} position={[20, -2, 0]}>
      <planeBufferGeometry attach="geometry" args={[100, 100]} />
      <meshStandardMaterial attach="material" color="white" />
    </mesh>
  );
};
// Lights
const KeyLight = ({ brightness, color }) => {
  return (
    <rectAreaLight
      width={3}
      height={3}
      color={color}
      intensity={brightness}
      position={[-2, 0, 5]}
      lookAt={[0, 0, 0]}
      penumbra={1}
      castShadow
    />
  );
};
const FillLight = ({ brightness, color }) => {
  return (
    <rectAreaLight
      width={3}
      height={3}
      intensity={brightness}
      color={color}
      position={[2, 1, 4]}
      lookAt={[0, 0, 0]}
      penumbra={2}
      castShadow
    />
  );
};

const RimLight = ({ brightness, color }) => {
  return (
    <rectAreaLight
      width={2}
      height={2}
      intensity={brightness}
      color={color}
      position={[1, 4, -2]}
      rotation={[0, 180, 0]}
      castShadow
    />
  );
};
const Scene = ({ handleClick }) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);
  const fbx = useLoader(FBXLoader, "2.fbx");
  return (
    <motion3d.primitive
      initial="hidden"
      animate="visible"
      whileHover={{ rotateX: -3, rotateY: 5, rotateZ: 20 }}
      variants={{
        hidden: { y: -1, rotateX: 0, rotateY: 0, rotateZ: 0 },
        visible: { y: 1, rotateX: 0, rotateY: 0, rotateZ: 0 },
      }}
      transition={{ repeat: Infinity, repeatType: "mirror", duration: 10 }}
      object={fbx}
      scale={0.003}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      onClick={handleClick}
    />
  );
};

const TopCanvas = ({ handleClick }) => {
  return (
    <Canvas
      style={{ width: "100vw", height: "100vh", zIndex: 101 }}
      camera={{
        aspect: window.innerWidth / window.innerHeight,
        fov: 100,
        near: 0.1,
        far: 1000,
      }}
    >
      <GroundPlane />
      <Roof />
      <BackDrop />
      <Front />
      <Left />
      <Right />
      <OrbitControls
        enableZoom={false}
        autoRotate={true}
        autoRotateSpeed={0.3}
      />

      <KeyLight brightness={5.6} color={"#90c0a0"} />
      <FillLight brightness={10} color={"#bdefff"} />
      <RimLight brightness={50} color={"#42FF91"} />

      <directionalLight
        position={[10, 30, 20]}
        intensity={0.7}
        color="#90c0a0"
      />
      <directionalLight
        position={[-10, -10, -5]}
        intensity={0.3}
        color="#90c0a0"
      />
      <Scene handleClick={handleClick} />
      <Stars />
    </Canvas>
  );
};

export default TopCanvas;
