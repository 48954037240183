import styled from "styled-components";
import { useState, useEffect, useRef, forwardRef } from "react";
import LogoWhite from "./assets/LogoWhite";
import { BsArrowDownCircle } from "react-icons/bs";
import TopCanvas from "./components/TopCanvas";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import image_3d_1 from "./assets/3d_1.png";
import image_3d_2 from "./assets/3d_2.png";
import image_3d_3 from "./assets/3d_3.png";
import image_3d_4 from "./assets/3d_4.png";

const moveToContent = (ref) => {
  ref.current?.scrollIntoView({ behavior: "smooth" });
};
const cardVariants = {
  offscreen: {
    scale: 0,
    y: 0,
  },
  onscreen: {
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const App = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  // const { scrollYProgress } = useScroll({
  //   offset: ["30vh", "70vh"],
  // });
  const { scrollYProgress, scrollY } = useScroll();

  // const y1 = useTransform(
  //   scrollYProgress,
  //   [0.4, 0.5, 0.7, 1.0],
  //   [110, -110, -110, -330]
  // );
  // const y2 = useTransform(
  //   scrollYProgress,
  //   [0.1, 0.5, 0.7, 1.0],
  //   [220, 0, 0, -220]
  // );
  const y1 = useTransform(
    scrollY,
    [300, 500, 1200, 1400],
    [110, -120, 290, -330]
  );
  const y2 = useTransform(scrollY, [300, 500, 1200, 1400], [220, 0, 410, -220]);
  const y3 = useTransform(scrollY, [300, 2000], [-100, 900]);
  const y4 = useTransform(scrollY, [300, 2000], [400, -800]);
  const x4 = useTransform(scrollY, [300, 2000], [0, -100]);
  const y5 = useTransform(scrollY, [300, 2000], [-200, 600]);
  const rotate5 = useTransform(scrollY, [300, 2000], [0, 30]);
  const y6 = useTransform(scrollY, [300, 2000], [-200, 0]);
  const rotate6 = useTransform(scrollY, [300, 2000], [30, -70]);

  const scale = useTransform(scrollY, [300, 500, 1200, 1400], [0, 1.2, 1.2, 3]);
  const opacity = useTransform(scrollY, [300, 500, 1200, 1400], [0, 1, 1, 0]);
  const opacity3d = useTransform(
    scrollY,
    [300, 700, 1200, 1400],
    [0, 0.5, 0.5, 0]
  );
  const opacity3d2 = useTransform(
    scrollY,
    [300, 700, 1200, 1400],
    [0, 0.8, 0.8, 0]
  );
  return (
    <Background>
      <Top>
        <TopCanvas
          handleClick={() => {
            moveToContent(ref1);
          }}
        />
        <GoDownIcon
          onClick={() => {
            moveToContent(ref1);
          }}
          style={{ zIndex: 102 }}
        />
      </Top>
      <Contents ref={ref1}>
        <motion.div
          style={{
            position: "sticky",
            top: 0,
            width: "100vw",
            height: "10px",
            backgroundColor: "#42FF91",
            scaleX: scrollYProgress,
            transformOrigin: "0%",
            zIndex: 100,
          }}
        />
        <motion.div
          ref={ref2}
          // initial="offscreen"
          // whileInView="onscreen"
          // variants={cardVariants}
          style={{ y: y2, zIndex: 2, marginTop: "200px" }}
        >
          <LogoWhite
            style={{
              width: "200px",
              height: "auto",
              zIndex: 1,
              opacity: 0,
            }}
          />
        </motion.div>
        <motion.p
          style={{
            y: y1,
            scale,
            opacity,
            color: "#42FF91",
            rotate: 0,
            fontSize: "40px",
            zIndex: 2,
            fontWeight: 700,
            textShadow: "0px 0px 20px #42FF91",
          }}
        >
          우리들만의 아지트
        </motion.p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Img3d1
            src={image_3d_1}
            alt="image_3d_1"
            style={{
              y: y3,
              opacity: opacity3d,
            }}
          />
          <Img3d2
            src={image_3d_4}
            alt="image_3d_4"
            style={{
              x: x4,
              y: y4,
              opacity: opacity3d,
            }}
          />
          <Img3d3
            src={image_3d_3}
            alt="image_3d_3"
            style={{
              rotate: rotate5,
              y: y5,
              opacity: opacity3d,
            }}
          />
          <Img3d4
            src={image_3d_2}
            alt="image_3d_2"
            style={{
              rotate: rotate6,
              y: y6,
              opacity: opacity3d2,
            }}
          />
        </div>
      </Contents>
    </Background>
  );
};

export default App;

const Background = styled.div`
  background-color: #000000;
`;
const Top = styled.div`
  height: 100vh;
  display: "flex";
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const GoDownIcon = styled(BsArrowDownCircle)`
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translate(-50%);
  color: #00000090;
  font-weight: 800;
  cursor: pointer;
`;
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400vh;
  max-width: 100%;
  overflow-x: hidden;
`;
const Img3d1 = styled(motion.img)`
  width: 200px;
  margin-top: -400px;
  margin-right: 300px;
  @media screen and (max-width: 768px) {
    width: 150px;
    margin-right: 225px;
  }
`;
const Img3d2 = styled(motion.img)`
  width: 250px;
  margin-left: 400px;
  margin-top: -50px;
  transform: rotate(45deg);
  z-index: 3;
  @media screen and (max-width: 768px) {
    width: 187.5px;
    margin-left: 200px;
  }
`;
const Img3d3 = styled(motion.img)`
  width: 250px;
  margin-right: 100px;
  margin-top: -50px;
  transform: rotate(20deg);
  z-index: 0;
  @media screen and (max-width: 768px) {
    width: 187.5px;
    margin-right: 75px;
  }
`;
const Img3d4 = styled(motion.img)`
  width: 300px;
  margin-left: 200px;
  margin-top: 100px;
  z-index: 3;
  @media screen and (max-width: 768px) {
    width: 225px;
    margin-left: 150px;
  }
`;
